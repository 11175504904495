import UnividButton from "../components/buttons/UnividButton";
import styles from "./CookieBanner.module.css";
import { useDispatch } from "react-redux";

import { setCookieConsent } from "../redux/slices/cookieSlice";

const CookieBanner = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.CookieBanner}>
      <div>
        We use cookies to improve our services, serve personlized ads or content
        and analyze our traffic.
        <br /> By clicking "Accept" you consent to the usage of our cookies.
        Read more in our <a href="https://univid.io/privacy/">Privacy policy</a>
        .
      </div>
      <div className={styles.ButtonContainer}>
        <UnividButton
          color={"gray"}
          onClick={() => {
            dispatch(setCookieConsent(false));
          }}
          fullWidth={true}
        >
          Accept Necessary
        </UnividButton>
        <UnividButton
          color={"green"}
          onClick={() => {
            dispatch(setCookieConsent(true));
          }}
          fullWidth={true}
        >
          Accept All
        </UnividButton>
      </div>
    </div>
  );
};

export default CookieBanner;
