import { createSlice } from "@reduxjs/toolkit";
import { THEME_COLORS } from "../../components/cssvariables/colors";

const defaultTheme = THEME_COLORS[0];

const initialState = {
  hasFetchedTheme: false,

  backgroundImage:
    "https://static.univid.io/platform/backgrounds/univid-bg-logos-falling-transparent.png",
  uploadedBackgroundImage: null,
  useBackgroundBlur: false,
  primaryColor: defaultTheme.primaryColor,
  accentColor: defaultTheme.accentColor,
  headerFade: null,
  corners: "round",
  headerLogo: null,
  splashImage: null,
  uploadedSplashImage: null,
  useSplashImageInConference: false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      const { payload } = action;

      if ("hasFetchedTheme" in payload) {
        state.hasFetchedTheme = payload.hasFetchedTheme;
      }

      if ("backgroundImage" in payload) {
        state.backgroundImage = payload.backgroundImage;
      }

      if ("uploadedBackgroundImage" in payload) {
        state.uploadedBackgroundImage = payload.uploadedBackgroundImage;
      }

      if ("splashImage" in payload) {
        state.splashImage = payload.splashImage;
      }

      if ("uploadedSplashImage" in payload) {
        state.uploadedSplashImage = payload.uploadedSplashImage;
      }

      if ("headerLogo" in payload) {
        state.headerLogo = payload.headerLogo;
      }

      if ("useBackgroundBlur" in payload) {
        state.useBackgroundBlur = payload.useBackgroundBlur;
      }

      if ("useSplashImageInConference" in payload) {
        state.useSplashImageInConference = payload.useSplashImageInConference;
      }

      if ("corners" in payload) {
        state.corners = payload.corners;
      }

      if ("primaryColor" in payload) {
        if (payload.primaryColor !== null) {
          state.primaryColor = payload.primaryColor;
        } else {
          state.primaryColor = defaultTheme.primaryColor;
        }
      }

      if ("accentColor" in payload) {
        if (payload.accentColor !== null) {
          state.accentColor = payload.accentColor;
        } else {
          state.accentColor = defaultTheme.accentColor;
        }
      }

      if ("headerFade" in payload) {
        state.headerFade = payload.headerFade;
      } else if ("primaryColor" in payload || "accentColor" in payload) {
        // If colors are set, but no gradient, remove the gradient
        state.headerFade = null;
      }
    },

    resetTheme: () => initialState,
  },
});

export const { setTheme, resetTheme } = themeSlice.actions;

export default themeSlice.reducer;
