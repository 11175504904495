import { AwsRum } from "aws-rum-web";
import * as Sentry from "@sentry/react";

const applicationId = "93a08bf2-38af-4a25-a9fa-4d60a0caecac";
const applicationVersion = "1.0.0";
const applicationRegion = "eu-north-1";

const ignoredErrors = [
  /^Object Not Found Matching Id/, // Thrown by loader after chunk loading failed
];

const shouldIgnoreError = (errorEvent) => {
  if (!errorEvent?.message) {
    return false;
  }

  return ignoredErrors.some((regex) => regex.test(errorEvent.message));
};

const productionAWSRUMConfig = {
  sessionSampleRate: 1,
  guestRoleArn:
    "arn:aws:iam::667201597712:role/RUM-Monitor-eu-north-1-667201597712-5926220613661-Unauth",
  identityPoolId: "eu-north-1:2e6c4d73-5ad2-4b61-96c0-6f1f2f476325",
  endpoint: "https://dataplane.rum.eu-north-1.amazonaws.com",
  telemetries: ["performance", ["errors", { stackTraceLength: 500 }], "http"],
  allowCookies: true,
  enableXRay: false,
};

let logger = {
  type: null,
  instance: null,
};

const setup = (type) => {
  if (type === "aws-rum") {
    // Do not run aws-rum for development
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    try {
      const awsRum = new AwsRum(
        applicationId,
        applicationVersion,
        applicationRegion,
        productionAWSRUMConfig,
      );

      logger = {
        type: "aws-rum",
        instance: awsRum,
      };
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
      console.error(error);
    }
  } else {
    logger = {
      type: "sentry",
    };
  }
};

const reportError = (error) => {
  const ignoreError = shouldIgnoreError(error);

  if (ignoreError) {
    return;
  }

  if (logger.type === "aws-rum") {
    return logger.instance?.recordError(error);
  } else if (logger.type === "sentry") {
    return Sentry.captureException(error);
  } else {
    return console.error(error);
  }
};

export { setup, reportError };
