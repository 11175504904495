import axios from "axios";
import { VARIABLES } from "../strings/variables";
import { reportError } from "../logging/logger.js";

const setRegularDateTimeFormat = async ({ dateTimeFormat }) =>
  axios.post(
    `${VARIABLES.apiUrl}user/date-time-format`,
    {
      dateTimeFormat,
    },
    {
      withCredentials: true,
    },
  );

const setNicknameDateTimeFormat = async ({ dateTimeFormat }) =>
  axios.post(
    `${VARIABLES.apiUrl}nickname/date-time-format`,
    {
      dateTimeFormat,
    },
    {
      withCredentials: true,
    },
  );

export const getClientDateTimeFormat = () =>
  Intl.DateTimeFormat().resolvedOptions();

export const setUserTimeZone = async ({ isRegularUser }) => {
  const dateTimeFormat = getClientDateTimeFormat();

  try {
    if (isRegularUser) {
      await setRegularDateTimeFormat({ dateTimeFormat });
    } else {
      await setNicknameDateTimeFormat({ dateTimeFormat });
    }
  } catch (error) {
    reportError(error);
    console.error(error);
  }
};
