import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import userReducer from "./reducers/userReducer";
import themeSlice from "./slices/themeSlice";
import sessionSlice from "./slices/sessionSlice";
import meetingSlice from "./slices/meetingSlice";
import cookieSlice from "./slices/cookieSlice";
import browserSlice from "./slices/browserSlice";
import callToActionSlice from "./slices/callToActionSlice";
import organizationSlice from "./slices/organizationSlice";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: {
    user: userReducer,
    theme: themeSlice,
    session: sessionSlice,
    meetingInfo: meetingSlice,
    cookie: cookieSlice,
    browserInfo: browserSlice,
    callToAction: callToActionSlice,
    organization: organizationSlice,
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat([sentryReduxEnhancer]);
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["session/setSessionInformation"],
        ignoredPaths: ["session.description"],
      },
    });
  },
});

export default store;
