import { createSlice } from "@reduxjs/toolkit";
const initState = {
  currentActiveCta: null,
  singleLinkCtas: [],
  multipleLinksCtas: [],
};

const callToActionSlice = createSlice({
  name: "callToAction",
  initialState: initState,
  reducers: {
    initCtas: (state, action) => {
      if (action.payload != null) {
        action.payload.singleLinkCtas = action.payload.singleLinkCtas ?? [];
        action.payload.multipleLinksCtas =
          action.payload.multipleLinksCtas ?? [];

        const ctaList = [
          ...action.payload.singleLinkCtas,
          ...action.payload.multipleLinksCtas,
        ];

        const currentCta = ctaList.find(
          ({ _id }) => _id === action.payload.currentActiveCtaId,
        );

        state.currentActiveCta = currentCta ?? null;
        state.singleLinkCtas = action.payload.singleLinkCtas ?? [];
        state.multipleLinksCtas = action.payload.multipleLinksCtas ?? [];
      } else {
        state.currentActiveCta = initState.currentActiveCta;
        state.singleLinkCtas = initState.singleLinkCtas ?? [];
        state.multipleLinksCtas = initState.multipleLinksCtas ?? [];
      }
    },

    addCta: (state, action) => {
      if (action.payload.newCallToAction.type === "singleLink") {
        state.singleLinkCtas.push(action.payload.newCallToAction);
      } else if (action.payload.newCallToAction.type === "multipleLinks") {
        state.multipleLinksCtas.push(action.payload.newCallToAction);
      }
    },

    removeCta: (state, action) => {
      const removedActionId = action.payload.callToActionId;
      state.singleLinkCtas = state.singleLinkCtas.filter(
        ({ _id }) => _id !== removedActionId,
      );
      state.multipleLinksCtas = state.multipleLinksCtas.filter(
        ({ _id }) => _id !== removedActionId,
      );
    },

    activateCta: (state, action) => {
      const ctaList = [...state.singleLinkCtas, ...state.multipleLinksCtas];
      const newCta = ctaList.find(
        ({ _id }) => _id === action.payload.callToActionId,
      );
      state.currentActiveCta = newCta;
    },

    disableCta: (state) => {
      state.currentActiveCta = null;
    },
  },
});

export const { initCtas, addCta, removeCta, activateCta, disableCta } =
  callToActionSlice.actions;
export default callToActionSlice.reducer;
