// TODO: Add back Sentry
// import "./tracking/setupSentry.js";

import "react-app-polyfill/stable";

import { createRoot } from "react-dom/client";

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { IntercomProvider } from "react-use-intercom";

import EnforceTrailingSlash from "./EnforceTrailingSlash.js";
import StaticSelector from "./StaticSelector.js";
import App from "./App.js";
import store from "./redux/store.js";

import CookieConsent from "./tracking/CookieConsent.js";
import IntercomWrapper from "./tracking/intercom/IntercomWrapper.js";

import LogInPage from "./components/login/LogInPage";
import { VideoPlayerEmbedRedirect } from "./components/videocontainer/videoplayers/VideoPlayerEmbedRedirect.js";
import { Suspense } from "react";
import lazyComponentLoader from "./utils/lazy/lazyComponentLoader.js";

// "jtnc9kzz" is the test workspace app id
const INTERCOM_APP_ID = "q634mzts";

window.HELP_IMPROVE_VIDEOJS = false; // Disable video.js analytics

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const StaticPlayer = lazyComponentLoader(
  () => import("./components/static/player.js"),
);

const RootRedirect = lazyComponentLoader(() => import("./RootRedirect.js"));

const CreateSession = lazyComponentLoader(
  () => import("./components/createsession/CreateSession"),
);

const SessionWrapper = lazyComponentLoader(
  () => import("./components/session/SessionWrapper"),
);

const SessionContainer = lazyComponentLoader(
  () => import("./components/session/SessionContainer"),
);

const RegistrationPage = lazyComponentLoader(
  () => import("./components/session/registration/RegistrationPage"),
);

const VideoTester = lazyComponentLoader(
  () => import("./components/videotester/VideoTester"),
);

const VideoPlayerEmbed = lazyComponentLoader(
  () => import("./components/videocontainer/videoplayers/VideoPlayerEmbed"),
);

const SessionLoginWrapper = lazyComponentLoader(
  () => import("./components/session/login/SessionLoginWrapper"),
);

const LoginUnividPage = lazyComponentLoader(
  () => import("./components/session/login/LoginUnividPage"),
);

const LoginGooglePage = lazyComponentLoader(
  () => import("./components/session/login/LoginGooglePage"),
);

const LoginFacebookPage = lazyComponentLoader(
  () => import("./components/session/login/LoginFacebookPage"),
);

const EnrichRegPartDataButton = lazyComponentLoader(
  () => import("./components/session/EnrichRegPartDataButton"),
);

const Space = lazyComponentLoader(() => import("./components/spaces/Space"));

const SubscriptionsPage = lazyComponentLoader(
  () => import("./components/subscriptions/SubscriptionsPage"),
);

const MyProfilePage = lazyComponentLoader(
  () => import("./components/profile/MyProfilePage"),
);

const NoOrganizationPage = lazyComponentLoader(
  () => import("./components/organization/NoOrganizationPage"),
);

const CreateOrganizationPage = lazyComponentLoader(
  () => import("./components/organization/create/CreateOrganizationPage"),
);

const OrganizationWrapper = lazyComponentLoader(
  () => import("./components/organization/OrganizationWrapper"),
);

const WebinarsPage = lazyComponentLoader(
  () => import("./components/organization/webinars/WebinarsPage"),
);

const MembersSettings = lazyComponentLoader(
  () => import("./components/organization/members/MembersSettings"),
);

const IntegrationsPage = lazyComponentLoader(
  () => import("./components/organization/integrations/IntegrationsPage"),
);

const WebinarTemplates = lazyComponentLoader(
  () => import("./components/organization/webinarTemplates/WebinarTemplates"),
);

const PdfViewer = lazyComponentLoader(
  () => import("./components/pdf/PdfViewer"),
);

const RecorderEndpoint = lazyComponentLoader(
  () => import("./components/videocontainer/meeting/RecorderEndpoint"),
);

const VideoTest = lazyComponentLoader(
  () => import("./components/videotest/VideoTest"),
);

const IframeTest = lazyComponentLoader(
  () => import("./components/dev/IframeTest.js"),
);

const SimLiveWrapper = lazyComponentLoader(
  () =>
    import("./components/videocontainer/videoplayers/simlive/SimLiveWrapper"),
);

const DevUnividAppsPage = lazyComponentLoader(
  () => import("./components/DevUnividAppsPage.js"),
);

const SalesforceOAuthCallback = lazyComponentLoader(
  () => import("./components/oauth-callback/SalesforceOAuthCallback"),
);

const OAuthCallback = lazyComponentLoader(
  () => import("./components/oauth-callback/OAuthCallback"),
);

const SuperadminPage = lazyComponentLoader(
  () => import("./components/SuperadminPage"),
);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<EnforceTrailingSlash />}>
      <Route element={<StaticSelector />}>
        <Route path="static">
          <Route path="player" element={<StaticPlayer />} />
        </Route>
        <Route element={<App />}>
          <Route element={<CookieConsent />}>
            <Route element={<IntercomWrapper />}>
              <Route index element={<RootRedirect />} />

              <Route
                path="webinars/template-create"
                element={<CreateSession />}
              />
              <Route path="createSession" element={<CreateSession />} />
              <Route path="create-webinar" element={<CreateSession />} />

              <Route path="session/:id" element={<SessionWrapper />}>
                <Route index element={<SessionContainer />} />

                <Route path="register" element={<RegistrationPage />} />

                <Route path="embed" element={<VideoPlayerEmbed />} />

                <Route path="login" element={<SessionLoginWrapper />}>
                  {/* TODO: Replace this default with "common" login */}
                  <Route index element={<LoginUnividPage />} />
                  <Route path="univid" element={<LoginUnividPage />} />
                  <Route path="google" element={<LoginGooglePage />} />
                  <Route path="facebook" element={<LoginFacebookPage />} />
                </Route>

                <Route
                  path="xlsx-enrich"
                  element={<EnrichRegPartDataButton />}
                />
              </Route>

              <Route path="space/:id" element={<Space />} />

              <Route path="subscription" element={<SubscriptionsPage />} />

              {/* Explicitly goes to the login page */}
              <Route path="login" element={<LogInPage />} />
              <Route
                path="signup"
                element={<LogInPage initState={"register"} />}
              />

              <Route path="profile" element={<MyProfilePage />} />
              <Route path="profile/:id" element={<MyProfilePage />} />

              <Route path="organization">
                <Route index element={<NoOrganizationPage />} />
                <Route path="create" element={<CreateOrganizationPage />} />
                <Route path=":organizationId" element={<OrganizationWrapper />}>
                  <Route index element={<Navigate to="webinars" replace />} />
                  <Route path="webinars" element={<WebinarsPage />} />
                  <Route path="members">
                    <Route path="settings" element={<MembersSettings />} />
                  </Route>
                  <Route path="integrations" element={<IntegrationsPage />} />

                  <Route path="templates" element={<WebinarTemplates />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="pdfviewer" element={<PdfViewer />} />
          <Route path="studiorecordendpoint" element={<RecorderEndpoint />} />
          <Route
            path="studiorecordendpoint/:id"
            element={<RecorderEndpoint />}
          />

          {/* Remove eventually */}
          <Route path="embed/:id" element={<VideoPlayerEmbedRedirect />} />
          <Route path="videotester" element={<VideoTester />} />
          <Route path="videoplayer" element={<VideoTester />} />
          {process.env.NODE_ENV === "development" && (
            <Route path="dev">
              <Route path="video-test" element={<VideoTest />} />
              <Route path="iframe-test" element={<IframeTest />} />
              <Route path="simlivetest" element={<SimLiveWrapper />} />
              <Route path="univid-apps" element={<DevUnividAppsPage />} />
            </Route>
          )}

          <Route
            path="salesforce/oauth2/callback"
            element={<SalesforceOAuthCallback />}
          />
          <Route path="oauth-callback" element={<OAuthCallback />} />

          <Route path="bobby-tables/hunter2" element={<SuperadminPage />} />

          {/* The default -> Just redirect to base url (TODO: Should probably rather give some kind of a 404 warning?) */}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Route>
    </Route>,
  ),
);

const root = createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={<div />}>
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </IntercomProvider>
  </Suspense>,
);
