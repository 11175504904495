import styles from "./ProceedToConsent.module.css";

const ProceedToConsent = () => {
  return (
    <span className={styles.ConsentTextContainer}>
      By proceeding, I consent to Univid's &nbsp;
      <a
        className={styles.ConsentLink}
        href="https://univid.io/terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </a>
      &nbsp;and&nbsp;
      <a
        className={styles.ConsentLink}
        href="https://univid.io/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Agreement
      </a>
    </span>
  );
};

export default ProceedToConsent;
