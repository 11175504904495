import TagManager from "react-gtm-module";

const gtmId = "GTM-MQ6SWTH";

const configuration = {
  production: {
    auth: "M8c82F4G0x5cZ6WV4HrqhA",
    preview: "env-1",
  },
  default: {
    auth: "CEAcesGeDf5J1BXxHRTIQw",
    preview: "env-28",
  },
};

const setupGTM = () => {
  const envSpecificConfig =
    configuration[process.env.NODE_ENV] ?? configuration.default;

  TagManager.initialize({
    gtmId,
    ...envSpecificConfig,
    dataLayerName: "dataLayerGTM",
  });
};

export default setupGTM;
