const baseStyle = {
  height: "25px",
  width: "103%",
  border: "none",
  textAlign: "center",
  verticalAlign: "center",
  boxShadow: "0 2px 4px 0 rgba(0,0,0,.25)",
  fontSize: "12px",
  lineHeight: "25px",
  display: "block",
  transition: "background-color .218s, border-color .218s, box-shadow .218s",
  fontFamily: "Lato,arial,sans-serif",
  cursor: "pointer",
  userSelect: "none",
  marginBottom: "20px",
  borderRadius: "5px",
};

export const darkStyle = {
  backgroundColor: "#4285f4",
  color: "#fff",
  ...baseStyle,
};

export const lightStyle = {
  backgroundColor: "#fff",
  color: "rgba(0,0,0,.54)",
  ...baseStyle,
};

export const iconStyle = {
  width: "23px",
  height: "23px",
  textAlign: "center",
  verticalAlign: "center",
  display: "block",
  marginTop: "1px",
  marginLeft: "1px",
  float: "left",
  backgroundColor: "#fff",
  whiteSpace: "nowrap",
  borderRadius: "3px",
};

export const svgStyle = {
  width: "23px",
  height: "23px",
  display: "block",
};

export const hoverStyle = {
  boxShadow: "0 0 3px 3px rgba(66,133,244,.3)",
  transition: "background-color .218s, border-color .218s, box-shadow .218s",
};

export const disabledStyle = {
  backgroundColor: "rgba(37, 5, 5, .08)",
  color: "rgba(0, 0, 0, .40)",
  cursor: "not-allowed",
};

export const disabledIconStyle = {
  backgroundColor: "transparent",
};
