import { useParams } from "react-router-dom";

const useBetterParams = () => {
  const params = useParams();

  const newParams = {};

  for (const entry of Object.entries(params)) {
    const entryKey = entry[0];
    let entryValue = entry[1];

    if (entryValue.includes("?")) {
      entryValue = entryValue.split("?")[0];
    }

    newParams[entryKey] = entryValue;
  }

  return newParams;
};

export default useBetterParams;
