import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./CustomTextInput.module.css";

const CustomTextInput = ({
  value,
  id,
  type,
  name,
  placeholder,
  autoComplete = "off",
  onChange,
  valid = true,
  readOnly = false,
  required = false,
  onBlur,
  minLength,
  maxLength,
  pattern,
  fieldInfo,
  translatePlaceholder = false,
}) => {
  const isEmpty = value === "";

  return (
    <div className={styles.InputContainer}>
      <div className={styles.InputWrapper}>
        <input
          id={id}
          className={classNames(styles.CustomTextInput, "no-translate", {
            [styles.CustomTextInputNotEmpty]: !isEmpty,
            [styles.Error]: !valid,
            [styles.ReadOnly]: readOnly,
          })}
          type={type}
          value={value}
          name={name}
          autoComplete={autoComplete}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          pattern={pattern}
        />
        <span
          className={classNames(styles.InputPlaceholder, {
            "no-translate": !translatePlaceholder,
          })}
        >
          {placeholder}
        </span>
      </div>
      {fieldInfo && <div className={styles.FieldInfo}>{fieldInfo}</div>}
    </div>
  );
};

CustomTextInput.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

export default CustomTextInput;
