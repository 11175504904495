import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  meetingIsActive: false,
  additionalRTMPDestination: null,
};

const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    setMeetingActive: () => {
      return { ...initialState, meetingIsActive: true };
    },
    setMeetingInactive: () => initialState,
    setMeetingData: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setMeetingActive, setMeetingInactive, setMeetingData } =
  meetingSlice.actions;
export default meetingSlice.reducer;
