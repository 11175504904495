import { useState } from "react";
import PropTypes from "prop-types";

import { reportError } from "../../logging/logger";
import styles from "./ErrorBoundary.module.css";
import ExpandableText from "../session/leftpanelmenu/ExpandableText";
import classNames from "classnames";
import * as Sentry from "@sentry/react";

const reloadPage = () => window.location.reload();

const ErrorBoundary = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorComponentStack, setErrorComponentStack] = useState(null);
  const [hasError, setHasError] = useState(false);

  const hasErrorInformation = errorMessage || errorComponentStack;

  const fallBack = (
    <div className={styles.ErrorBoundary}>
      <div className={styles.GeneralInfo}>
        <h2 className={styles.Heading}>Unfortunately something went wrong</h2>
        <p>
          We will look into it as soon as possible. While we fix the problem you
          can try reloading the page.
        </p>
        <button className={styles.Button} onClick={reloadPage}>
          Reload page
        </button>
        <p>
          Feel free to reach out to{" "}
          <a href="mailto:hello@univid.io">hello@univid.io</a> for assistance.
        </p>
      </div>
      {hasErrorInformation && (
        <div className={classNames(styles.Details, "no-translate")}>
          <ExpandableText maxHeight={25}>
            <h3 className={styles.Subheading}>Detailed error message</h3>
            {errorMessage && (
              <p className={styles.ErrorMessage}>{errorMessage}</p>
            )}
            {errorComponentStack && (
              <p className={styles.ComponentStack}>{errorComponentStack}</p>
            )}
          </ExpandableText>
        </div>
      )}
    </div>
  );

  const handleError = (error, errorInfo) => {
    const errorMessage = error.message;
    const errorComponentStack = errorInfo?.componentStack || null;

    setHasError(true);
    setErrorMessage(errorMessage);
    setErrorComponentStack(errorComponentStack);

    const messageAndComponentStack = `ErrorBoundary: ${errorMessage} ${errorComponentStack}`;
    const details = messageAndComponentStack
      .replaceAll("\n", "")
      .substring(0, 100);
    const detailedCaughtError = new Error(details, {
      cause: { stack: errorComponentStack },
    });

    reportError(detailedCaughtError);
  };

  return (
    <Sentry.ErrorBoundary fallback={fallBack} onError={handleError}>
      {!hasError && children}
    </Sentry.ErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
