const initialState = {
  currentUser: {},
  hasFetchedUser: false,
  isAuthenticated: false,
  isAuthenticatedUnauth: false,
  redirectToLogin: false,
  authFailed: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        currentUser: action.userObj,
        isAuthenticated: action.isUnauth != true,
        isAuthenticatedUnauth: action.isUnauth == true,
        redirectToLogin: action.isUnauth == true,
        hasFetchedUser: true,
      };

    case "LOGOUT_USER":
      return {
        ...state,
        currentUser: {},
        isAuthenticated: false,
        isAuthenticatedUnauth: false,
        redirectToLogin: true,
        authFailed: action.authFailed,
        hasFetchedUser: true,
      };

    case "UPDATE_USER":
      return {
        ...state,
        currentUser: action.userObj,
        hasFetchedUser: true,
      };

    case "RESET_AUTH":
      return { ...state, authFailed: false, hasFetchedUser: true };

    default:
      return state;
  }
}
