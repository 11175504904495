// TODO: COLORS should be based on the unividDefaultGray theme
export const COLORS = {
  primary: "rgb(73, 111, 140)",
  primaryStrong: "rgba(73, 111, 140, 0.8)",
  primarySoft: "rgba(73, 111, 140, 0.5)",
  primaryHint: "rgba(73, 111, 140, 0.1)",

  secondary: "rgb(138, 195, 240)",
  secondaryStrong: "rgba(138, 195, 240, 0.8)",
  secondarySoft: "rgba(138, 195, 240, 0.5)",
  secondaryHint: "rgba(138, 195, 240, 0.1)",

  accent: "rgb(122, 58, 113)",
  accentStrong: "rgba(122, 58, 113, 0.8)",
  accentSoft: "rgb(122, 58, 113, 0.5)",
  accentHint: "rgb(122, 58, 113, 0.1)",
};

export const THEME_COLORS = [
  {
    primaryColor: "#fff7f6",
    accentColor: "#4f94fa",
  },
  {
    primaryColor: "#febfc7",
    accentColor: "#d17a5d",
    gradient: {
      startColor: "#febfc7",
      endColor: "#fed9dd",
    },
  },
  {
    primaryColor: "#ebccb9",
    accentColor: "#a67c5b",
    gradient: {
      startColor: "#ebccb9",
      endColor: "#f3e0d5",
    },
  },
  {
    primaryColor: "#2f170b",
    accentColor: "#9f7a68",
    gradient: {
      startColor: "#2f170b",
      endColor: "#bb9582",
    },
  },
  {
    primaryColor: "#134f82",
    accentColor: "#4a7cb8",
    gradient: {
      startColor: "#134f82",
      endColor: "#4299e3",
    },
  },
  {
    primaryColor: "#385d3b",
    accentColor: "#5d8a5a",
    gradient: {
      startColor: "#385d3b",
      endColor: "#78ae7b",
    },
  },
  {
    primaryColor: "#98caff",
    accentColor: "#4f8de3",
    gradient: {
      startColor: "#98caff",
      endColor: "#c5e1ff",
    },
  },
  {
    primaryColor: "#f3c7e2",
    accentColor: "#b364c2",
    gradient: {
      startColor: "#f3c7e2",
      endColor: "#f8ddee",
    },
  },
  {
    primaryColor: "#a379fd",
    accentColor: "#4a7cd9",
    gradient: {
      startColor: "#a379fd",
      endColor: "#d1bcfe",
    },
  },
];
