import { createSlice } from "@reduxjs/toolkit";
import browserUtils from "../../components/badbrowser/utils.js";

const initialState = {
  browserName: null,
  browserVersion: null,
  goodBrowser: false,
  goodVersion: false,
  canUseBackgroundEffects: false,
  canSelectAudioOutput: false,
  isIphoneOrIpad: false,
  isSet: false,
};

const browserSlice = createSlice({
  name: "browser",
  initialState,
  reducers: {
    initializeBrowserInfo: (state) => {
      const newBrowserInformation = browserUtils.getCurrentBrowser();
      Object.assign(state, newBrowserInformation);
      state.isSet = true;
    },
    resetBrowserInfo: () => initialState,
  },
});

export const { initializeBrowserInfo, resetBrowserInfo } = browserSlice.actions;

export default browserSlice.reducer;
