import { Navigate, Outlet } from "react-router-dom";
import useBetterLocation from "./components/utils/useBetterLocation.js";

const EnforceTrailingSlash = () => {
  const { pathname, search } = useBetterLocation();

  if (pathname.length > 0 && pathname[pathname.length - 1] !== "/") {
    return <Navigate to={`${pathname}/${search}`} replace={true} />;
  }

  return <Outlet />;
};

export default EnforceTrailingSlash;
