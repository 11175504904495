import { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { GoogleIcon } from "./icons";

import styles from "./LoginButtons.module.css";

import { VARIABLES } from "../../../strings/variables.js";

class GoogleButton extends Component {
  constructor(props) {
    super(props);

    const url = window.location.search.substring(1);
    this.params = queryString.parse(url);

    const addRoomSpacesQueryString = this.params.addRoomSpaces;
    const hasValidRoomSpacesToAdd =
      typeof addRoomSpacesQueryString === "string" &&
      addRoomSpacesQueryString.length > 0;
    this.roomSpacesToAdd = hasValidRoomSpacesToAdd
      ? addRoomSpacesQueryString.split(",").map((element) => element.trim())
      : null;

    const addRoomsQueryString = this.params.addRooms;
    const hasValidRoomsToAdd =
      typeof addRoomsQueryString === "string" && addRoomsQueryString.length > 0;

    this.roomsToAdd = hasValidRoomsToAdd
      ? addRoomsQueryString.split(",").map((element) => element.trim())
      : null;

    this.initSubscription = this.params.psubscription ?? "";

    this.urlToOpen = this.createUrlToOpen();
  }

  createUrlToOpen() {
    // Can this be moved or made async somehow? (i.e. only happen upon successful login)? Probably not

    const authUrl = VARIABLES.apiUrl + "auth/google";

    const allParams = [];

    allParams.push({
      key: "pageURL",
      value: encodeURIComponent(window.location.href),
    });

    allParams.push({
      key: "pageTitle",
      value: encodeURIComponent(document.title),
    });

    if (this.roomsToAdd != null) {
      allParams.push({
        key: "roomsToAdd",
        value: encodeURIComponent(this.roomsToAdd),
      });
    }

    if (this.roomSpacesToAdd != null) {
      allParams.push({
        key: "roomSpacesToAdd",
        value: encodeURIComponent(this.roomSpacesToAdd),
      });
    }

    if (this.props.roomToRegister != null) {
      allParams.push({
        key: "roomToRegister",
        value: encodeURIComponent(this.props.roomToRegister),
      });
    }

    if (this.props.addToHubSpot != null) {
      allParams.push({
        key: "addToHubSpot",
        value: encodeURIComponent(this.props.addToHubSpot ?? false),
      });
    }

    if (this.initSubscription != null) {
      allParams.push({
        key: "launchSubscriptionTrial",
        value: encodeURIComponent(this.initSubscription),
      });
    }

    let queryParams = "";

    for (let paramsIdx = 0; paramsIdx < allParams.length; paramsIdx++) {
      if (paramsIdx === 0) {
        queryParams += "?";
      } else {
        queryParams += "&";
      }

      queryParams +=
        allParams[paramsIdx].key + "=" + allParams[paramsIdx].value;
    }

    return authUrl + queryParams;
  }

  render() {
    return (
      <div
        className={styles.SocialMediaButtonContainer}
        style={{ flexGrow: this.props.simple ? 0 : 1 }}
      >
        <a
          id={"google-login-button"}
          className={styles.GoogleButton}
          href={this.urlToOpen}
          style={{
            flexGrow: this.props.simple ? 0 : 1,
            borderRadius: this.props.theme.corners == "sharp" ? "0px" : null,
          }}
        >
          <GoogleIcon />
          <span style={{ marginLeft: "5px" }}>
            {this.props.simple ? "Google" : "Continue with Google"}
          </span>
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(GoogleButton);
