import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import useBetterGetDimensions from "./components/utils/useBetterGetDimensionsHook.js";

const isMobile = require("is-mobile");
const isMobileUnit = isMobile();

const StaticSelector = () => {
  const dimensionsObject = isMobileUnit ? useBetterGetDimensions() : null;

  useEffect(() => {
    let newWidthString = "1vw";
    let newHeightString = "1vh";

    // Will only apply if mobile
    if (dimensionsObject != null) {
      const newWidth = dimensionsObject.width;
      const newHeight = dimensionsObject.height;

      if (newWidth != null && newHeight != null) {
        newWidthString = newWidth * 0.01 + "px";
        newHeightString = newHeight * 0.01 + "px";
      }
    }

    const styleElement =
      document.getElementById("body") ?? document.documentElement;

    styleElement.style.setProperty("--vw", newWidthString);
    styleElement.style.setProperty("--vh", newHeightString);
  }, [dimensionsObject]);

  return <Outlet />;
};

export default StaticSelector;
