/* global FB */
import { Component } from "react";
import { connect } from "react-redux";

import { addFacebookScript } from "../../scripts/utilscripts.js";
import { FaFacebook } from "react-icons/fa";
import styles from "./LoginButtons.module.css";

import queryString from "query-string";

import { tokenFacebookFetch } from "../../../redux/actions/userActions";
import { reportError } from "../../../logging/logger.js";

class FacebookLogin extends Component {
  constructor(props) {
    super(props);

    const url = window.location.search.substring(1);
    this.params = queryString.parse(url);

    const addRoomSpacesQueryString = this.params.addRoomSpaces;
    const hasValidRoomSpacesToAdd =
      typeof addRoomSpacesQueryString === "string" &&
      addRoomSpacesQueryString.length > 0;
    this.roomSpacesToAdd = hasValidRoomSpacesToAdd
      ? addRoomSpacesQueryString.split(",").map((element) => element.trim())
      : null;

    const addRoomsQueryString = this.params.addRooms;
    const hasValidRoomsToAdd =
      typeof addRoomsQueryString === "string" && addRoomsQueryString.length > 0;
    this.roomsToAdd = hasValidRoomsToAdd
      ? addRoomsQueryString.split(",").map((element) => element.trim())
      : null;

    this.initSubscription = this.params.psubscription ?? "";

    this.state = {
      didMountFacebookScript: false,
    };
  }

  componentDidMount() {
    const runFacebookScript = async () => {
      try {
        await addFacebookScript();

        if (FB == null) {
          throw new Error("FB is undefined");
        }

        const params = {
          appId: "691197054801826",
          cookie: false,
          xfbml: false,
          version: "v16.0",
        };

        FB.init(params);

        this.setState({
          didMountFacebookScript: true,
        });
      } catch (error) {
        reportError(error);
        console.log(error.name, ":", error.message);
      }
    };

    runFacebookScript();
  }

  handleClick = () => {
    if (!this.state.didMountFacebookScript) {
      alert("Facebook login is not available on this browser");
      return;
    }

    try {
      FB.getLoginStatus((loginStatusResponse) => {
        const params = {
          provider: "facebook",
          roomsToAdd: this.roomsToAdd,
          roomSpacesToAdd: this.roomSpacesToAdd,
          roomToRegister: this.props.roomToRegister,
          addToHubSpot: this.props.addToHubSpot ?? false,
          pageURL: window.location.href,
          pageTitle: document.title,
          launchSubscriptionTrial: this.initSubscription,
        };

        if (loginStatusResponse.status === "connected") {
          params.uid = loginStatusResponse.authResponse.accessToken;

          return this.props.tokenFacebookFetch(params);
        } else {
          FB.login(
            (loginResponse) => {
              if (loginResponse.authResponse) {
                params.uid = loginResponse.authResponse.accessToken;

                return this.props.tokenFacebookFetch(params);
              }
            },
            { scope: "email" },
          );
        }
      });
    } catch (error) {
      reportError(error);
      // TODO: add error resp here - suggest turning of mozilla GUARD symbol (disable tracking)
      console.log(
        "error with auth ocurred - please try turning off Mozilla tracking protection",
        error,
      );
      if (navigator && navigator.userAgent) {
        const firefoxAgent = navigator.userAgent.indexOf("Firefox") > -1;
        if (firefoxAgent && this.props.setAndDisplayErrorMessage != null) {
          const errorMessage =
            "Please try turning of tracking protection in Mozilla, change browser, or login/signup via Univid directly.";
          this.props.setAndDisplayErrorMessage(errorMessage);
        }
      }
    }
  };

  render() {
    return (
      <div
        className={styles.SocialMediaButtonContainer}
        style={{ flexGrow: this.props.simple ? 0 : 1 }}
      >
        <button
          id={"facebook-login-button"}
          onClick={this.handleClick}
          className={styles.FacebookLoginButtonWrapper}
          style={{
            flexGrow: this.props.simple ? 0 : 1,
            borderRadius: this.props.theme.corners == "sharp" ? "0px" : null,
          }}
        >
          <FaFacebook className={styles.FacebookLoginButton} />
          <span style={{ marginLeft: "5px" }}>
            {this.props.simple ? "Facebook" : "Continue with Facebook"}
          </span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    theme: state.theme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  tokenFacebookFetch: (accessToken) =>
    dispatch(tokenFacebookFetch(accessToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookLogin);
