import { useSelector } from "react-redux";
import { useEffect } from "react";

const borderRadiusFromCorners = (corners) =>
  corners === "round" ? "5px" : "none";

const updateCSSVariables = ({ primaryColor, accentColor, corners }) => {
  const styleElement =
    document.getElementById("body") ?? document.documentElement;

  styleElement.style.setProperty("--unividPrimaryColor", primaryColor);
  styleElement.style.setProperty("--unividAccentColor", accentColor);
  styleElement.style.setProperty(
    "--unividCornerBorderRadius",
    borderRadiusFromCorners(corners),
  );
  styleElement.style.setProperty("--deleteColor", "#d11a2a");
  styleElement.style.setProperty("--darkBlue", "#1e5fa6");
};

const useThemeStyle = () => {
  const theme = useSelector((state) => state.theme);

  useEffect(() => {
    updateCSSVariables(theme);
  }, [theme]);
};

export default useThemeStyle;
