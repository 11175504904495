export const VARIABLES = {
  apiUrl: process.env.REACT_APP_BACKENDIP
    ? process.env.REACT_APP_BACKENDIP
    : process.env.REACT_APP_IS_STAGING != null
      ? "https://api-staging.univid.io/"
      : process.env.NODE_ENV === "production" || process.env.REACT_APP_TESTLIVE
        ? "https://api.univid.io/"
        : "http://localhost:3001/",
  facebookAppId: "691197054801826",
  appOrigin:
    process.env.NODE_ENV === "production"
      ? "https://app.univid.io"
      : "http://localhost:3000",
};
