import { createSlice } from "@reduxjs/toolkit";
import consentCookies from "../../tracking/consentCookies";

const initialState = {
  consentState: consentCookies.getGtmConsent(),
};

const cookieSlice = createSlice({
  name: "cookie",
  initialState,
  reducers: {
    setCookieConsent: (state, action) => {
      consentCookies.setGtmConsent(action.payload);
      state.consentState = action.payload;
    },
  },
});

export const { setCookieConsent } = cookieSlice.actions;
export default cookieSlice.reducer;
