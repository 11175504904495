import { useEffect, useState } from "react";

import getDimensions from "./dimensionsutils";

const useBetterGetDimensions = () => {
  const [dimensionsObject, setDimensionsObject] = useState(getDimensions());

  useEffect(() => {
    const updateDimensions = () => {
      setDimensionsObject(getDimensions());
    };

    window.addEventListener("resize", updateDimensions);
    window.matchMedia("(orientation: portrait)").addListener(updateDimensions);
    window.addEventListener("orientationchange", updateDimensions);
  }, []);

  return dimensionsObject;
};

export default useBetterGetDimensions;
