import { Component } from "react";
import { connect } from "react-redux";

import { FadeLoader } from "react-spinners";

import queryString from "query-string";

import {
  getUserFetch,
  userLoginFetch,
  resetAuth,
  userUnauthLoginFetch,
} from "../../redux/actions/userActions";

import styles from "./RegisterLoginInline.module.css";
import FacebookLogin from "./authenticationbuttons/FacebookLogin";
import GoogleButton from "./authenticationbuttons/GoogleButton";

import ProceedToConsent from "./consent/ProceedToConsent";

import { VARIABLES } from "../../strings/variables.js";
import trackEvent from "../../tracking/trackEvent";
import CustomTextInput from "../textinput/CustomTextInput";
import { reportError } from "../../logging/logger";

import axios from "axios";
import AlertMessage from "../alertmessage/AlertMessage.js";

const validInitStates = [
  "login",
  "register",
  "upgrade",
  "newpassword",
  "forgotpassword",
];
class RegisterLogInInline extends Component {
  constructor(props) {
    super(props);

    const url = window?.location?.search?.substring(1);
    this.params = queryString.parse(url);

    const addRoomSpacesQueryString = this.params.addRoomSpaces;
    const hasValidRoomSpacesToAdd =
      typeof addRoomSpacesQueryString === "string" &&
      addRoomSpacesQueryString.length > 0;
    this.roomSpacesToAdd = hasValidRoomSpacesToAdd
      ? addRoomSpacesQueryString.split(",").map((element) => element.trim())
      : null;

    const addRoomsQueryString = this.params.addRooms;
    const hasValidRoomsToAdd =
      typeof addRoomsQueryString === "string" && addRoomsQueryString.length > 0;
    this.roomsToAdd = hasValidRoomsToAdd
      ? addRoomsQueryString.split(",").map((element) => element.trim())
      : null;

    let initState = props.initState ?? "login";

    if (props.initState == null && "initstate" in this.params) {
      if (validInitStates.includes(this.params.initstate)) {
        initState = this.params.initstate;
      }
    }

    let usernameInit = this.params.pemail ?? "";
    let firstNameInit = this.params.pfname ?? "";
    let lastNameInit = this.params.plname ?? "";
    let profilePictureInit = null;

    this.initSubscription = this.params.psubscription ?? "";

    if (this.props.isAuthenticatedUnauth) {
      if (
        typeof this.props.currentUser.email === "string" &&
        this.props.currentUser.email.length > 0
      ) {
        usernameInit = this.props.currentUser.email;
      }
      // Prioritize first name entered, otherwise, suggest username as first name
      if (
        typeof this.props.currentUser.first_name === "string" &&
        this.props.currentUser.first_name.length > 0
      ) {
        firstNameInit = this.props.currentUser.first_name;
      } else if (
        typeof this.props.currentUser.username === "string" &&
        this.props.currentUser.username.length > 0
      ) {
        firstNameInit = this.props.currentUser.username;
      }

      if (
        typeof this.props.currentUser.last_name === "string" &&
        this.props.currentUser.last_name.length > 0
      ) {
        lastNameInit = this.props.currentUser.last_name;
      }

      if (
        typeof this.props.currentUser.provider_pic === "string" &&
        this.props.currentUser.provider_pic.length > 0
      ) {
        profilePictureInit = this.props.currentUser.provider_pic;
      }
    }

    this.state = {
      user: {
        username: {
          value: usernameInit,
          error: false,
        },
        first_name: {
          value: firstNameInit,
          error: false,
        },
        last_name: {
          value: lastNameInit,
          error: false,
        },
        password: {
          value: "",
          error: false,
        },
        consentEmails: {
          value: false,
          error: false,
        },

        // Currently only to be used with nickname converted users
        profile_picture: {
          value: profilePictureInit,
          error: false,
        },
      },
      newPassword: {
        password: {
          value: "",
          error: false,
        },
        confirmation: {
          value: "",
          error: false,
        },
      },

      loading: false,
      userState: initState,
      displayError: false,
      errorMessage: "",
      canCreateUser: false,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.authFailed) {
      props.resetAuth();

      return {
        loading: false,
      };
    }
    // Return null to indicate no change to state.
    return null;
  }

  hasValidUsername = (username) => {
    return (
      typeof username === "string" &&
      username.length >= 2 &&
      username.length <= 40
    );
  };

  hasValidFirstName = (firstName) => {
    return (
      typeof firstName === "string" &&
      firstName.length >= 2 &&
      firstName.length <= 40
    );
  };

  hasValidLastName = (lastName) => {
    return (
      typeof typeof lastName === "string" &&
      lastName.length >= 0 &&
      lastName.length <= 40
    );
  };

  validateEmail = (e) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        username: {
          ...prevState.user.username,
          error: !e.target.validity.valid,
        },
      },
    }));
  };

  checkValidity = () => {
    const hasValidUsername = this.hasValidUsername(
      this.state.user.username.value,
    );
    const hasValidFirstName = this.hasValidFirstName(
      this.state.user.first_name.value,
    );
    const hasValidLastName = this.hasValidLastName(
      this.state.user.last_name.value,
    );

    const canCreateUser =
      hasValidUsername && hasValidFirstName && hasValidLastName;

    if (this.state.canCreateUser != canCreateUser) {
      this.setState({ canCreateUser });
    }
  };

  componentDidUpdate() {
    this.checkValidity();
  }

  componentDidMount() {
    this.checkValidity();
  }

  render() {
    if (this.props.isAuthenticated) {
      return <div />;
    }

    const accentColor = this.props.theme?.accentColor ?? styles.primary;

    return (
      <div
        className={styles.LoginWrapper}
        style={this.props.disablePadding ? { padding: 0 } : {}}
      >
        {this.props.needToRegister && (
          <div className={styles.SessionRegistrationBodyHeader}>
            The host of this session asks you to register before attending
          </div>
        )}

        {this.state.loading && (
          <div className={styles.LoadingScreen}>
            <FadeLoader color={accentColor} loading={this.state.loading} />
          </div>
        )}

        {this.props.showWelcomeMessage && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                fontSize: "x-large",
                fontWeight: 500,
                marginBottom: "6px",
                color: accentColor,
              }}
            >
              {this.state.userState === "upgrade"
                ? "Upgrade your account!"
                : "Welcome"}
            </div>
            {["login", "register", "upgrade"].includes(
              this.state.userState,
            ) && (
              <div>
                {this.props.customHintText ??
                  (this.state.userState === "upgrade"
                    ? "Upgrade to a full Univid account by completing the fields below."
                    : "Log in or create an account below.")}
              </div>
            )}
          </div>
        )}

        {!this.props.hideSocialMedia &&
          (this.state.userState === "register" ||
            this.state.userState === "login") && (
            <div className={styles.InputSection}>
              <div className={styles.LoginAlternativeContainer}>
                <GoogleButton addToHubSpot={this.props.addToHubSpot} />

                <FacebookLogin
                  setAndDisplayErrorMessage={(errorMsg) =>
                    this.setAndDisplayErrorMessage(errorMsg)
                  }
                  addToHubSpot={this.props.addToHubSpot}
                />
              </div>
              <div
                className={styles.HorizontalDividerContainer}
                style={{ marginTop: "10px" }}
              >
                <div className={styles.HorizontalDividerWrapper}>
                  <div className={styles.HorizontalDivider} />
                  <div className={styles.LoginDivider}>or</div>

                  <div className={styles.HorizontalDivider} />
                </div>
              </div>
            </div>
          )}

        {/* BELOW SHOULD BE APPROX THE SAME */}
        {this.state.userState === "login" && (
          <div className={styles.InputContainer}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.authenticate();
              }}
              className={styles.InputSection}
            >
              <div className={styles.InputSubContainer}>
                <CustomTextInput
                  id={"login-email-input"}
                  className={styles.CustomTextInput}
                  type="email"
                  value={this.state.user.username.value}
                  onChange={(evt) => this.updateUsername(evt)}
                  required
                  name="email"
                  autoComplete="email"
                  placeholder="Email"
                  valid={!this.state.user.username.error}
                  onBlur={this.validateEmail}
                />
              </div>
              <div className={styles.InputSubContainer}>
                <CustomTextInput
                  type="password"
                  value={this.state.user.password.value}
                  onChange={(evt) => this.updatePassword(evt)}
                  required
                  autoComplete="current-password"
                  placeholder="Password"
                />
              </div>
              <div className={styles.RegisterLoginChoice}>
                <button
                  type="submit"
                  className={
                    styles.MainChoiceButton +
                    " " +
                    styles.MainChoiceButtonAvailable
                  }
                  style={{
                    borderRadius:
                      this.props.theme.corners === "sharp" ? "0px" : null,
                    backgroundColor: accentColor,
                  }}
                >
                  Log in
                </button>

                <div className={styles.SecondaryButtonContainer}>
                  <button
                    className={styles.SecondaryChoiceButton}
                    onClick={() => this.setUserState("forgotpassword")}
                  >
                    Forgot your password?
                  </button>
                </div>
              </div>

              {this.state.displayError && (
                <AlertMessage errorMessage={this.state.errorMessage} />
              )}
            </form>
          </div>
        )}
        {(this.state.userState === "register" ||
          this.state.userState === "upgrade") && (
          <div className={styles.InputContainer}>
            <form
              className={styles.InputSection}
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.canCreateUser) {
                  const isUpgrading = this.state.userState === "upgrade";

                  this.register(isUpgrading);
                }
              }}
            >
              <div className={styles.InputSubContainer}>
                <CustomTextInput
                  id="username"
                  type="email"
                  value={this.state.user.username.value}
                  onChange={(evt) => this.updateUsername(evt)}
                  required
                  autoComplete="email"
                  placeholder={"Email"}
                  valid={!this.state.user.username.error}
                  onBlur={this.validateEmail}
                />
              </div>

              <div className={styles.InputSubContainer}>
                <CustomTextInput
                  id="fname"
                  type="text"
                  value={this.state.user.first_name.value}
                  onChange={(evt) => this.updateFirstName(evt)}
                  style={{ marginRight: "10px" }}
                  required
                  autoComplete="given-name"
                  placeholder={"First Name"}
                  valid={!this.state.user.first_name.error}
                />
                <CustomTextInput
                  id="lname"
                  type="text"
                  value={this.state.user.last_name.value}
                  onChange={(evt) => this.updateLastName(evt)}
                  required
                  autoComplete="family-name"
                  placeholder={"Last Name"}
                  valid={!this.state.user.last_name.error}
                />
              </div>
              <div className={styles.InputSubContainer}>
                <CustomTextInput
                  id="pwd"
                  type="password"
                  value={this.state.user.password.value}
                  onChange={(evt) => this.updatePassword(evt)}
                  required
                  autoComplete="new-password"
                  placeholder={"Password"}
                  valid={!this.state.user.password.error}
                />
              </div>

              <div className={styles.RegisterLoginChoice}>
                <button
                  type="submit"
                  className={
                    styles.MainChoiceButton +
                    " " +
                    (this.state.canCreateUser
                      ? styles.MainChoiceButtonAvailable
                      : styles.MainChoiceButtonDisabled)
                  }
                  style={{
                    borderRadius:
                      this.props.theme.corners === "sharp" ? "0px" : null,
                    backgroundColor: accentColor,
                  }}
                >
                  {this.state.userState === "upgrade" ? "Upgrade" : "Register"}
                </button>
              </div>
              <ProceedToConsent />
            </form>
            {this.state.displayError && (
              <AlertMessage errorMessage={this.state.errorMessage} />
            )}
          </div>
        )}

        {this.state.userState === "register" && (
          <div className={styles.SwitchModeButtonContainer}>
            <span className={styles.SwitchModeButtonHint}>
              Already have an account?
            </span>

            <button
              className={styles.SwitchModeButton}
              onClick={() => this.setUserState("login")}
            >
              Log in
            </button>
          </div>
        )}

        {this.state.userState === "login" && (
          <div className={styles.SwitchModeButtonContainer}>
            <span className={styles.SwitchModeButtonHint}>
              Don't have an account yet?
            </span>
            <button
              className={styles.SwitchModeButton}
              onClick={() => this.setUserState("register")}
            >
              Create account
            </button>
          </div>
        )}

        {this.state.userState === "sentnewpasswordemail" && (
          <div className={styles.InputContainer}>
            <div className={styles.InputSection}>
              <span className={styles.DescriptionWrapper}>
                A password reset email is on the way to{" "}
                {this.state.user.username.value}
              </span>
              <span className={styles.ParanthesesWrapper}>
                (It might have ended up in the spam folder)
              </span>
              <button
                onClick={() => this.sendResetPasswordEmail()}
                className={styles.ResendWrapper}
              >
                Re-send
              </button>
            </div>
          </div>
        )}
        {this.state.userState === "newpasswordset" && (
          <div className={styles.InputContainer}>
            <div className={styles.InputSection}>
              <span className={styles.DescriptionWrapper}>
                Your password has been updated!
              </span>
            </div>
          </div>
        )}
        {this.state.userState === "forgotpassword" && (
          <div className={styles.InputContainer}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.sendResetPasswordEmail();
              }}
              className={styles.InputSection}
            >
              <div className={styles.InputSubContainer}>
                <CustomTextInput
                  type="email"
                  value={this.state.user.username.value}
                  onChange={(evt) => this.updateUsername(evt)}
                  required
                  name="email"
                  autoComplete="email"
                  placeholder={"Email"}
                  valid={!this.state.user.username.error}
                  onBlur={this.validateEmail}
                />
              </div>
              <div className={styles.RegisterLoginChoice}>
                <button
                  type="submit"
                  className={
                    styles.MainChoiceButton +
                    " " +
                    styles.MainChoiceButtonAvailable
                  }
                  style={{
                    borderRadius:
                      this.props.theme.corners == "sharp" ? "0px" : null,
                    backgroundColor: accentColor,
                  }}
                >
                  Request new password
                </button>
              </div>
            </form>
            {this.state.displayError && (
              <AlertMessage errorMessage={this.state.errorMessage} />
            )}
          </div>
        )}
        {this.state.userState === "newpassword" && (
          <div className={styles.InputContainer}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.setNewPassword();
              }}
              className={styles.InputSection}
            >
              <div className={styles.InputSubContainer}>
                <CustomTextInput
                  type="password"
                  value={this.state.newPassword.password.value}
                  onChange={(evt) => this.updateNewPassword(evt)}
                  required
                  name="newpassword"
                  autoComplete="new-password"
                  placeholder={"New Password"}
                />
              </div>
              <div className={styles.InputSubContainer}>
                <CustomTextInput
                  type="password"
                  value={this.state.newPassword.confirmation.value}
                  onChange={(evt) => this.updateNewPasswordConfirmation(evt)}
                  required
                  name="newpasswordconfirmation"
                  autoComplete="new-password"
                  placeholder={"Confirm Password"}
                />
              </div>
              <div className={styles.RegisterLoginChoice}>
                <button
                  type="submit"
                  className={
                    styles.MainChoiceButton +
                    " " +
                    styles.MainChoiceButtonAvailable
                  }
                  style={{
                    borderRadius:
                      this.props.theme.corners === "sharp" ? "0px" : null,
                    backgroundColor: accentColor,
                  }}
                >
                  Save password
                </button>
              </div>
            </form>
          </div>
        )}

        {[
          "newpassword",
          "forgotpassword",
          "newpasswordset",
          "sentnewpasswordemail",
        ].includes(this.state.userState) && (
          <div className={styles.SecondaryButtonContainer}>
            <button
              className={styles.SecondaryChoiceButton}
              onClick={() => this.setUserState("login")}
            >
              Go to log in
            </button>
          </div>
        )}
      </div>
    );
  }

  updateFirstName(evt) {
    let newVal = evt.target.value;

    if (typeof newVal === "string" && newVal.length >= 1) {
      newVal = newVal.charAt(0).toUpperCase() + newVal.slice(1);
    }

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        first_name: {
          value: newVal,
          error: false,
        },
      },
    }));
  }

  updateLastName(evt) {
    let newVal = evt.target.value;

    if (typeof newVal === "string" && newVal.length >= 1) {
      newVal = newVal.charAt(0).toUpperCase() + newVal.slice(1);
    }

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        last_name: {
          value: newVal,
          error: false,
        },
      },
    }));
  }

  setAndDisplayErrorMessage(errorMessage) {
    this.setState({
      errorMessage,
      displayError: true,
    });
  }

  updateNewPassword(evt) {
    const newVal = evt.target.value;
    this.setState((prevState) => ({
      newPassword: {
        ...prevState.newPassword,
        password: {
          value: newVal,
          error: false,
        },
      },
    }));
  }

  updateNewPasswordConfirmation(evt) {
    const newVal = evt.target.value;
    this.setState((prevState) => ({
      newPassword: {
        ...prevState.newPassword,
        confirmation: {
          value: newVal,
          error: false,
        },
      },
    }));
  }

  updateUsername(evt) {
    const newVal = evt.target.value;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        username: {
          ...prevState.user.username,
          value: newVal,
        },
      },
    }));
  }

  updatePassword(evt) {
    const newVal = evt.target.value;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        password: {
          value: newVal,
          error: false,
        },
      },
    }));
  }

  updateConsentPrivacyAndTerms() {
    this.setState((prevState) => {
      const newVal = !prevState.user.consentPrivacyAndTerms.value;

      return {
        user: {
          ...prevState.user,
          consentPrivacyAndTerms: {
            value: newVal,
            error: false,
          },
        },
      };
    });
  }

  updateConsentEmails() {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        consentEmails: {
          value: !prevState.user.consentEmails.value,
          error: false,
        },
      },
    }));
  }

  setUserState(newValue) {
    this.setState({ userState: newValue, displayError: false });
  }

  async authenticate() {
    this.setState({
      loading: true,
    });

    const error = await this.props.userLoginFetch({
      username: this.state.user.username.value,
      password: this.state.user.password.value,
      roomsToAdd: this.roomsToAdd,
      roomSpacesToAdd: this.roomSpacesToAdd,
    });
    if (error?.response?.data) {
      this.setAndDisplayErrorMessage(
        "We couldn't log in to your account with that information.",
      );
    }

    this.setState({
      loading: false,
    });
  }

  setNewPassword() {
    if (!("pwtoken" in this.params)) {
      alert("Sorry, there is no reset password token");
      return;
    }

    if (
      this.state.newPassword.password.value !==
      this.state.newPassword.confirmation.value
    ) {
      alert("The passwords are not equal");
      return;
    }

    this.setState({
      loading: true,
    });
    axios
      .post(
        VARIABLES.apiUrl + "newpassword",
        {
          token: this.params.pwtoken,
          password: this.state.newPassword.password.value,
        },
        {
          withCredentials: true,
        },
      )
      .then(() => {
        this.setState({ loading: false, userState: "newpasswordset" });
        this.props.getUserFetch();
      })
      .catch((error) => {
        reportError(error);
        console.log("Got error when trying to set a password!", error);
        this.setState({
          loading: false,
        });
      });
  }

  sendResetPasswordEmail() {
    this.setState({
      loading: true,
    });
    axios
      .post(
        VARIABLES.apiUrl + "sendnewpassword",
        { username: this.state.user.username.value },
        {
          withCredentials: true,
        },
      )
      .then(() => {
        this.setState({ loading: false, userState: "sentnewpasswordemail" });
      })
      .catch((error) => {
        if (error.response?.data?.msg) {
          this.setAndDisplayErrorMessage(error.response.data.msg);
        }
        reportError(error);
        console.log("Got error from reset password email!", error);
        this.setState({
          loading: false,
        });
      });
  }

  register(isUpgrading) {
    this.setState({
      loading: true,
    });
    const user = {
      username: this.state.user.username.value,
      password: this.state.user.password.value,
      first_name: this.state.user.first_name.value,
      last_name: this.state.user.last_name.value,
      consent_privacy: true,
      consent_terms: true,
      consent_emails: this.state.user.consentEmails.value,
      provider_pic: this.state.user.profile_picture.value,
      send_verification: true,
      // Things to register
      roomsToAdd: this.roomsToAdd,
      roomSpacesToAdd: this.roomSpacesToAdd,
      // Subscription
      launchSubscriptionTrial: this.initSubscription,
    };

    axios
      .post(
        VARIABLES.apiUrl + "newuser",
        {
          userObject: user,
          isUpgrading: !!isUpgrading,
          addToHubSpot: !!this.props.addToHubSpot,
          pageURL: window.location.href,
          pageTitle: document.title,
        },
        { withCredentials: true },
      )
      .then((response) => {
        if (response.data.error) {
          if (
            response.data.error &&
            response.data.error.type &&
            response.data.error.type === "validation"
          ) {
            const userCopy = this.state.user;
            const userKeys = Object.keys(userCopy);

            for (let i = 0; i < userKeys.length; i++) {
              userCopy[userKeys[i]].error =
                response.data.error.wrongKeys.includes(userKeys[i].toString());
            }
            this.setState({ user: userCopy, loading: false });
          }
          this.setAndDisplayErrorMessage("Couldn't register.");
        } else if (response.data.success) {
          this.props.getUserFetch();

          // TODO: Fix so that there is a userID
          trackEvent({
            event: "user_create", // event name declared during initialization
            eventProps: {
              category: "User",
              action: "Create new user",
              label:
                "This user was created " +
                (response.data.user ? " " + response.data.user._id : ""),
              user_id: response.data.user && response.data.user._id,
            },
          });
        }
      })
      .catch((error) => {
        reportError(error);
        console.log("Got error from register!", error);
        this.setState({
          loading: false,
        });
      });
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    isAuthenticatedUnauth: state.user.isAuthenticatedUnauth,
    currentUser: state.user.currentUser,
    authFailed: state.user.authFailed,
    theme: state.theme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserFetch: (userInfo) => dispatch(getUserFetch(userInfo)),
  userLoginFetch: (userInfo) => dispatch(userLoginFetch(userInfo)),
  userUnauthLoginFetch: (userInfo) => dispatch(userUnauthLoginFetch(userInfo)),
  resetAuth: (userInfo) => dispatch(resetAuth(userInfo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterLogInInline);
