import { reportError } from "../../logging/logger";

/* REF: https://thebrainfiles.wearebrain.com/react-client-side-authentication-with-facebook-linkedin-and-google-fdaf7f3adf6d */
const addScript = (id, src) =>
  new Promise((resolve, reject) => {
    const element = document.getElementById(id);
    if (element && element !== null) {
      return resolve(true);
    }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", id);
    script.setAttribute("src", src);
    script.addEventListener("load", resolve);
    script.addEventListener("error", () =>
      reject(new Error(`Error loading ${id}.`)),
    );
    script.addEventListener("abort", () =>
      reject(new Error(`${id}  loading aborted.`)),
    );
    document.getElementsByTagName("head")[0].appendChild(script);
  }).catch((error) => {
    reportError(error);
    console.log("error ocurred when addScript", error);
  });

export const addFacebookScript = () => {
  const id = "facebookAuth";
  const src = "https://connect.facebook.net/en_US/sdk.js";
  return addScript(id, src);
};
