import { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styles from "./ExpandableText.module.css";

const ExpandableText = ({ maxHeight, children }) => {
  const divRef = useRef();

  const [viewFullDescription, setViewFullDescription] = useState(false);

  const [isOverflowing, setIsOverflowing] = useState(false);

  const [scrollHeight, setScrollHeight] = useState();

  const handleResize = useCallback((entries) => {
    const entry = entries[0];
    setScrollHeight(entry.target.scrollHeight);
  }, []);

  useEffect(() => {
    const newIsOverflowing = scrollHeight > maxHeight;

    setIsOverflowing(newIsOverflowing);
  }, [maxHeight, children, scrollHeight]);

  useEffect(() => {
    if (!divRef.current) {
      return;
    }
    let RO = new ResizeObserver((entries) => handleResize(entries));
    RO.observe(divRef.current);

    return () => {
      RO.disconnect();
      RO = null;
    };
  }, [divRef, handleResize]);

  return (
    <>
      <div className={styles.Description}>
        <div
          ref={divRef}
          className={styles.Wrapper}
          style={{
            maxHeight: viewFullDescription ? "none" : maxHeight,
          }}
        >
          {children}
          {!viewFullDescription && isOverflowing && (
            <div className={styles.FadeOut} />
          )}
        </div>
        {isOverflowing && (
          <button
            className={styles.FullDescriptionToggle}
            onClick={() => {
              setViewFullDescription(!viewFullDescription);
            }}
          >
            {viewFullDescription ? "See less" : "See more..."}
          </button>
        )}
      </div>
    </>
  );
};

ExpandableText.propTypes = {
  maxHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default ExpandableText;
