import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasFetchedRoomInfo: false,
  hasFetchedAccessData: false,

  // Deprecate below:
  isInSession: false,

  isLight: true,

  language: "en-gb",
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    addHost: (state, action) => {
      state.teachers.push(action.payload);
    },
    removeHost: (state, action) => {
      state.teachers = state.teachers.filter(
        (teacher) => teacher._id !== action.payload,
      );
    },
    setSessionInformation: (state, action) => {
      Object.assign(state, action.payload);
    },
    resetSessionInformation: () => initialState,
  },
});

export const {
  addHost,
  removeHost,
  setSessionInformation,
  resetSessionInformation,
} = sessionSlice.actions;

export default sessionSlice.reducer;
