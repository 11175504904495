import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./UnividCard.module.css";

const UnividCard = ({
  children,
  useDefaultWidth = true,
  padding = "normal",
  overflow = "auto",
  selected = false,
}) => {
  const className = classNames(styles.UnividCard, {
    [styles.DefaultWidth]: useDefaultWidth,
    [styles.PaddingSlim]: padding === "slim",
    [styles.Selected]: selected,
  });

  return (
    <div
      className={className}
      style={{
        overflow,
      }}
    >
      {children}
    </div>
  );
};

UnividCard.propTypes = {
  children: PropTypes.any,
  useDefaultWidth: PropTypes.bool,
  padding: PropTypes.oneOf(["normal", "slim"]),
  overflow: PropTypes.string,
};

export default UnividCard;
