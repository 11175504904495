import parser from "ua-parser-js";
import { isMobile, isIOS } from "react-device-detect";

const lowestBrowserVersionObject = {
  Chrome: 72,
  Firefox: 36,
  Opera: 60,
  Edge: 79,
};

// Support is based on Google Meet background effects support (as of 2021-09-03)
// https://support.google.com/meet/answer/10058482?hl=en-GB&co=GENIE.Platform%3DDesktop&oco=0#zippy=%2Crequirements-for-change-background-on-desktop
// const canUseBackgroundEffectsObject = { Chrome: 87, Opera: 73, Edge: 87 }; // Opera 73 is based on chromium m87

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
// const canSelectAudioOutputObject = { Chrome: 49, Opera: 36, Edge: 17 }; // Opera 73 is based on chromium m87

const hasWebglSupport = () => {
  try {
    const canvas = document.createElement("canvas");
    return (
      !!window.WebGLRenderingContext &&
      (!!canvas.getContext("webgl") ||
        !!canvas.getContext("experimental-webgl"))
    );
  } catch {
    return false;
  }
};

const hasAudioOutputSelectionSupport = () => {
  try {
    const audioElement = document.createElement("audio");
    return typeof audioElement.setSinkId === "function";
  } catch {
    return false;
  }
};

const getCurrentBrowser = () => {
  const platformObject = parser();

  let browserName = null;
  let browserVersion = null;

  const isIphoneOrIpad = isMobile && isIOS;

  let goodBrowser = false;
  let goodVersion = false;
  let canUseBackgroundEffects = false;
  let canSelectAudioOutput = false;
  let canUsePDFShare = false;

  if (platformObject.browser) {
    if (typeof platformObject.browser.name === "string") {
      browserName = platformObject.browser.name;
    }

    if (typeof platformObject.browser.major === "string") {
      const parsedVersion = parseInt(platformObject.browser.major);

      if (!isNaN(parsedVersion)) {
        browserVersion = parsedVersion;
      }
    }
  }

  if (browserName != null && browserName in lowestBrowserVersionObject) {
    goodBrowser = true;

    if (
      browserVersion != null &&
      browserVersion >= lowestBrowserVersionObject[browserName]
    ) {
      goodVersion = true;
    }
  }

  canUseBackgroundEffects = hasWebglSupport();

  canSelectAudioOutput = hasAudioOutputSelectionSupport();

  canUsePDFShare = browserName === "Chrome" || browserName === "Edge";

  const toReturn = {
    browserName,
    browserVersion,
    goodBrowser,
    goodVersion,
    canUseBackgroundEffects,
    canUsePDFShare,
    canSelectAudioOutput,
    isIphoneOrIpad,
  };

  return toReturn;
};

export default {
  getCurrentBrowser,
};
