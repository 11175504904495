import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { useIntercom } from "react-use-intercom";
import { parseUser } from "../../utils/parseUser.js";
import { useEffect, useState } from "react";

const IntercomWrapper = () => {
  const {
    boot: bootIntercom,
    update: updateIntercom,
    shutdown: shutdownIntercom,
  } = useIntercom();

  const [intercomIsBooted, setIntercomIsBooted] = useState(false);

  const currentUser = useSelector((state) => state.user.currentUser);

  const browserInfo = useSelector((state) => state.browserInfo);

  useEffect(() => {
    return () => {
      shutdownIntercom();
    };
  }, []);

  useEffect(() => {
    const { userName, userEmail, userId, intercomHmac, profilePic } =
      parseUser(currentUser);

    if (intercomIsBooted) {
      // Update Intercom props
      updateIntercom({
        name: userName ?? null,
        email: userEmail ?? null,
        userId: userId ?? null,
        userHash: intercomHmac ?? null,
        avatar: profilePic
          ? {
              type: "avatar",
              imageUrl: profilePic,
            }
          : null,
        customAttributes: {
          univid_plan_type: currentUser.subscription_level,
          univid_is_regular_user: currentUser.is_regular_user,
          univid_organization_id:
            currentUser.organizationInfo?.defaultOrganizationId,
          univid_is_dev: process.env.NODE_ENV === "development",
        },
      });
    }
  }, [currentUser, updateIntercom, intercomIsBooted]);

  useEffect(() => {
    if (
      browserInfo?.isSet &&
      !browserInfo.browserName?.toLowerCase().includes("headless")
    ) {
      bootIntercom();
      setIntercomIsBooted(true);
    }
  }, [bootIntercom, browserInfo]);

  return <Outlet />;
};

export default IntercomWrapper;
