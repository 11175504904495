export const parseUser = (user) => {
  const userName = user.is_regular_user
    ? user.first_name != null && user.last_name != null
      ? user.first_name + " " + user.last_name
      : "Visitor"
    : (user.username ?? "Visitor");
  const userEmail = user.email ?? null;
  const userId = user._id ?? null;
  const intercomHmac = user.intercomHmac ?? null;
  const profilePic = user.provider_pic ?? null;

  return {
    userName,
    userEmail,
    userId,
    intercomHmac,
    profilePic,
  };
};
