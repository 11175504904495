import { useLocation } from "react-router-dom";
import { reportError } from "../../logging/logger";

const useBetterLocation = () => {
  const location = useLocation();

  const newLocation = { ...location };

  try {
    const decodedPathname = decodeURIComponent(newLocation.pathname);
    const decodedExistingSearch = decodeURIComponent(newLocation.search);

    // TODO: DOES IT WORK IF EITHER OF THESE ARE TRUE SEPARATELY?
    if (decodedPathname.includes("?") || decodedExistingSearch.includes("?")) {
      let decodedPathnameSplit = decodedPathname.split("?");

      const newPathname = decodedPathnameSplit.shift(0);

      decodedPathnameSplit = decodedPathnameSplit.filter(
        (val) => val.length > 0,
      );

      const decodedExistingSearchSplit = decodedExistingSearch
        .split("?")
        .filter((val) => val.length > 0);

      newLocation.pathname = newPathname;

      newLocation.search = "";

      const allSearchParams = [
        ...decodedExistingSearchSplit,
        ...decodedPathnameSplit,
      ];

      // This preserves question marks
      while (allSearchParams.length > 0) {
        const nextSearchValue = allSearchParams.shift();
        newLocation.search += "?" + nextSearchValue;
      }
    }
  } catch (error) {
    reportError(error);
    console.log("Some error editing location");
    return location;
  }

  return newLocation;
};

export default useBetterLocation;
