import { Outlet } from "react-router-dom";
import setupGTM from "./setupGTM";
import { useSelector } from "react-redux";
import CookieBanner from "./CookieBanner";
import { setup as setupLogger } from "../logging/logger";

import setupGoogleAnalytics from "./setupGoogleAnalytics";

let hasInitiatedTracking = false;

const CookieConsent = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const isAuthenticatedUnauth = useSelector(
    (state) => state.user.isAuthenticatedUnauth,
  );
  const sessionInformation = useSelector((state) => state.session);
  const consentState = useSelector((state) => state.cookie.consentState);

  const isLoggedIn = isAuthenticated || isAuthenticatedUnauth;
  const isPrivacySession = sessionInformation.defaultPrivacy ?? false;
  const isInSession = sessionInformation.isInSession ?? false;

  let shouldRenderBanner = true;

  const hasDeniedCookies = consentState === false && !isLoggedIn;

  if (hasDeniedCookies && hasInitiatedTracking) {
    window.location.reload();
    shouldRenderBanner = false;
  } else if (
    hasDeniedCookies ||
    (isInSession && (isPrivacySession || !isLoggedIn)) // Either a privacy session or not logged in (i.e. in registration page)
  ) {
    // Do not want to setup GTM
    shouldRenderBanner = false;
  } else if (
    consentState === true ||
    isLoggedIn ||
    window.location.pathname.toLowerCase().includes("createsession") ||
    window.location.pathname.toLowerCase().includes("create-webinar")
  ) {
    if (!hasInitiatedTracking) {
      hasInitiatedTracking = true;

      setupGTM();
      setupLogger("sentry");

      if (process.env.NODE_ENV === "production") {
        setupGoogleAnalytics();
      }
    }

    shouldRenderBanner = false;
  }

  return (
    <>
      <Outlet />
      {shouldRenderBanner && <CookieBanner />}
    </>
  );
};

export default CookieConsent;
