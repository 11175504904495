import { reportError } from "../../logging/logger";

const retryTimeoutInMilliseconds = 1500;
const attemptCount = 3;

const componentLoader = (
  dynamicImport,
  description,
  attemptsLeft = attemptCount - 1,
) =>
  new Promise((resolve, reject) => {
    dynamicImport()
      .then(resolve)
      .catch((error) => {
        reportError(
          new Error(
            `${error.message} ${description} (${
              attemptCount - attemptsLeft
            }/${attemptCount})`,
          ),
        );

        if (attemptsLeft === 0) {
          reject(error);
          return;
        }

        setTimeout(() => {
          componentLoader(dynamicImport, description, attemptsLeft - 1).then(
            resolve,
            reject,
          );
        }, retryTimeoutInMilliseconds);
      });
  });

export default componentLoader;
