import { createSlice } from "@reduxjs/toolkit";
const initState = {};

const organizationSlice = createSlice({
  name: "organization",
  initialState: initState,
  reducers: {
    initOrganization: (state, action) => {
      state.organizationId = action.payload.organizationId;
      state.name = action.payload.name;
      state.hasHubspotIntegration = action.payload.hasHubspotIntegration;
      state.hasUpsalesIntegration = action.payload.hasUpsalesIntegration;
      state.subscription = action.payload.subscription;
      state.isInOrganization = true;
      state.hasFetchedOrganizationData = true;
    },
    resetOrganization: () => initState,
  },
});

export const { initOrganization, resetOrganization } =
  organizationSlice.actions;

export default organizationSlice.reducer;
