const isMobile = require("is-mobile");
const isMobileUnit = isMobile();

const detectIOSOrSafari = () => {
  if (navigator == null) {
    return false;
  }

  if (
    navigator.userAgent != null &&
    "ontouchend" in document &&
    ((!navigator.userAgent.includes("Chrome") &&
      navigator.userAgent.includes("Safari")) ||
      navigator.userAgent.includes("Mac"))
  ) {
    return true;
  }

  return (
    navigator.platform != null &&
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform)
  );
};

const isApple = detectIOSOrSafari();

const getDimensionsFunction = (methodPriorityArray) => {
  let widthSuggestion = null;
  let heightSuggestion = null;

  // method === undefined when length of methodPriorityArray is 0
  const method = methodPriorityArray.pop();

  switch (method) {
    case "A":
      if ("getBoundingClientRect" in document.body) {
        const bodyRect = document.body.getBoundingClientRect();
        heightSuggestion = bodyRect.bottom - bodyRect.top;
        widthSuggestion = bodyRect.right - bodyRect.left;
      }
      break;
    case "B":
      if (
        document.documentElement &&
        "clientHeight" in document.documentElement &&
        "clientWidth" in document.documentElement
      ) {
        widthSuggestion = document.documentElement.clientWidth;
        heightSuggestion = document.documentElement.clientHeight;
      }
      break;
    case "C":
      widthSuggestion = window.innerWidth;
      heightSuggestion = window.innerHeight;
      break;
    default:
      // Default method, return null
      return null;
  }

  if (
    widthSuggestion != null &&
    widthSuggestion != 0 &&
    heightSuggestion != null &&
    heightSuggestion != 0
  ) {
    return {
      width: widthSuggestion,
      height: heightSuggestion,
    };
  } else {
    return getDimensionsFunction(methodPriorityArray);
  }
};

const getDimensions = () => {
  let dimensionsObject = null;

  if (!isMobileUnit || isApple) {
    // Priority order A -> B -> C (reversed order in method call as .pop() is used)
    dimensionsObject = getDimensionsFunction(["C", "B", "A"]);
  } else {
    // Priority order B -> C (reversed order in method call as .pop() is used)
    dimensionsObject = getDimensionsFunction(["C", "B"]);
  }

  return dimensionsObject;
};

export default getDimensions;
